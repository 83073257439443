import * as Button from './../../components/Atoms/Button';
import * as Modal from './../../components/Atoms/Modal';

import React, { useEffect, useState } from 'react';
import { Panel } from 'primereact/panel';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import _ from 'lodash';

const Counseling = ({
  consultationList,
  registerConsultation,
  changeConsultation,
  NEW,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState({
    type: '',
    content: '',
  });
  const [selectedContent, setSelectedContent] = useState({
    dataId: 0,
    consultationGroupId: '',
    note: '',
    useYN: '',
    regTime: '',
    regBy: 0,
    modTime: null,
    modBy: null,
    delTime: null,
    delBy: null,
  });
  const [list, setList] = useState([]);
  const [showList, setShowList] = useState(false);
  const [allText, setAllText] = useState('');

  useEffect(() => {
    if (consultationList) {
      setList(consultationList);
    }
  }, [consultationList]);

  useEffect(() => {
    if (list) {
      const arr = [];
      list.map((el) =>
        arr.push(`[생성일시 : ${el.regTime.replace('T', ' ')}] \n${el.note}`)
      );
      setAllText(arr.join('\n\n'));
    }
  }, [list]);

  useEffect(() => {
    if (!showList) {
      sessionStorage.clear();
    }
  }, [showList]);

  return (
    <>
      <Panel
        header={
          <div className="flex justify-content-between align-items-center">
            <div>상담 이력</div>
          </div>
        }
        id="note"
        className="overflow-scroll h-auto mb-5"
      >
        <DataTable
          emptyMessage="이력이 없습니다."
          value={list}
          lazy
          showGridlines
          rowHover
          scrollable
          scrollHeight="20rem"
          onRowClick={(e) => {
            const { data } = e;
            setSelectedContent(data);
          }}
          className="border-solid border-gray-100 border-1 p-1 cursor-pointer"
        >
          <Column
            field="dtn"
            align={'center'}
            headerStyle={{
              display: 'flex',
              justifyContent: 'center',
            }}
            style={{
              maxWidth: '4rem',
            }}
            header="순번"
            body={(id, column) => <span>{column.rowIndex + 1}</span>}
          />
          <Column
            field="regTime"
            header="생성일시"
            align={'center'}
            headerStyle={{
              display: 'flex',
              justifyContent: 'center',
            }}
            style={{
              maxWidth: '8rem',
            }}
            body={({ regTime }) => <span>{regTime.replace('T', '\r\n')}</span>}
          />
          <Column
            field="note"
            header="내용"
            headerStyle={{
              display: 'flex',
              justifyContent: 'center',
            }}
            className="w-full overflow-hidden"
            body={({ note }) => {
              const length = note.length;
              const slicedContent = note.slice(0, 100);
              return (
                <div>
                  <span>{`${slicedContent}${length > 101 ? '...' : ''}`}</span>
                </div>
              );
            }}
          />
        </DataTable>

        {/* 테이블 더블 클릭하면 현재 글 보여주는 UI */}
        <div className=" m-0 grid mt-5">
          <div className="col-12 flex justify-content-between">
            <span className="font-semibold text-lg">
              {_.get(selectedContent, 'note') !== ''
                ? '상세 보기'
                : '상담 작성'}
            </span>
            {_.get(selectedContent, 'note') !== '' ? (
              <div className="flex gap-2">
                <Button.Default
                  type="button"
                  label="뒤로 가기"
                  className="p-button-outlined"
                  icon="pi pi-arrow-left"
                  onClick={() => {
                    setSelectedContent({
                      dataId: 0,
                      consultationGroupId: '',
                      note: '',
                      useYN: '',
                      regTime: '',
                      regBy: 0,
                      modTime: null,
                      modBy: null,
                      delTime: null,
                      delBy: null,
                    });
                  }}
                />
              </div>
            ) : (
              <Button.Default
                type="button"
                label="저장"
                onClick={() => {
                  //            창 닫고 작성한 내용 바깥 모달로 전달하기
                  registerConsultation(data.content);
                  setData({ ...data, content: '' });
                }}
                disabled={data.content === '' || NEW}
                // disabled={
                //   !(_.get(data, 'content') !== '' && _.get(data, 'type') !== '')
                // }
              />
            )}
          </div>
          {_.get(selectedContent, 'note') !== '' ? (
            <>
              <div className="grid col-12 pr-0">
                <div className="col-8">
                  <div className="p-inputgroup flex align-items-center">
                    <label htmlFor="" className="mr-2 font-semibold w-4rem">
                      생성일시
                    </label>
                    <InputText
                      value={_.get(selectedContent, 'regTime').replace(
                        'T',
                        ' '
                      )}
                      readOnly
                    />
                  </div>
                </div>
                <div className="col-4 flex justify-content-end gap-2 pr-0">
                  <Button.Default
                    type="button"
                    className="p-button-outlined"
                    label="수정"
                    onClick={() => {
                      changeConsultation(
                        selectedContent.dataId,
                        selectedContent.note,
                        'Y'
                      );
                    }}
                  />
                  <Button.Default
                    type="button"
                    label="삭제"
                    className="p-button-outlined p-button-danger"
                    onClick={() => {
                      setShowModal(true);
                    }}
                  />
                </div>
              </div>
            </>
          ) : (
            <div className="col-3">
              <div className="p-inputgroup flex align-items-center"></div>
            </div>
          )}
          <div className="col-12">
            <div className="p-inputgroup flex align-items-center">
              <InputTextarea
                placeholder={
                  NEW ? '클레임 저장 후 상담 작성이 가능합니다.' : ''
                }
                value={
                  selectedContent.note ? selectedContent.note : data.content
                }
                onChange={(e) => {
                  selectedContent.note
                    ? setSelectedContent({
                        ...selectedContent,
                        note: e.target.value,
                      })
                    : setData((ps) => ({
                        ...ps,
                        content: e.target.value,
                      }));
                }}
                rows={20}
                className="w-full ml-1 text-lg"
              />
            </div>
          </div>
        </div>
        {/* {!!list.length && (
          <div className="flex justify-content-end mt-4">
            <Button.Default
              icon="pi pi-external-link"
              type="button"
              className="p-button-help p-button-outlined"
              label="상담 이력 전체보기"
              onClick={() => {
                setShowList(true);
                sessionStorage.setItem('data', JSON.stringify(allText));
                CommonUtil.Window.popup(
                  '/compensation/accident/claim/detail/counseling',
                  '',
                  window.innerWidth * 0.8,
                  window.innerHeight * 0.8
                );
              }}
            />
          </div>
        )} */}
      </Panel>

      {/* {showList && (
        <Modal.Default
          onHide={() => setShowList(!showList)}
          header={'전체 상담이력'}
          widthClass="60rem"
        >
          <InputTextarea
            value={allText}
            onChange={(e) => {
              setAllText(e.target.value);
            }}
            rows={50}
            className="w-full ml-1 text-lg"
          />
        </Modal.Default>
      )} */}

      {showModal && (
        <Modal.Default
          onHide={() => setShowModal(!showModal)}
          header={'상담이력 삭제'}
          widthClass="30rem"
        >
          <div className="pb-4">해당 상담이력을 삭제하시겠습니까?</div>
          <div className="flex gap-4 justify-content-center	">
            <Button.Default
              // icon="pi pi-send"
              label="삭제"
              className="p-button-outlined p-button-danger"
              onClick={() => {
                changeConsultation(
                  selectedContent.dataId,
                  selectedContent.note,
                  'N'
                );
                setSelectedContent({
                  dataId: 0,
                  consultationGroupId: '',
                  note: '',
                  useYN: '',
                  regTime: '',
                  regBy: 0,
                  modTime: null,
                  modBy: null,
                  delTime: null,
                  delBy: null,
                });
                setShowModal(false);
              }}
            />
            <Button.Default
              // icon="pi pi-send"
              label="취소"
              className="p-button-outlined"
              onClick={() => setShowModal(false)}
            />
          </div>
        </Modal.Default>
      )}
    </>
  );
};

export default Counseling;
